import React, { FC, useState, useEffect, SyntheticEvent, FormEvent, useMemo } from "react";
import { Trans } from "react-i18next";
import { CirclePicker as ColorPicker } from "react-color";
import memoizee from "memoizee";
import { parse, isEqual, isAfter, isBefore, isDate, format } from "date-fns";

import Modal from "composants/Modal/Modal";
import { Field } from "composants/form/Form";
import { uuidv4 } from "utils/uuid.utils";
import { t } from "utils/i18n";
import { ComponentState } from "types/Component";
import Control from "composants/form/Control";
import { Pojo } from "types/Galaxy";
import { convertValue } from "utils/entities.utils";

interface HighlightDialogProps {
  columns: ComponentState[];
  highlights: HighlightProperties[];
  selectedHighlightId?: string | null;
  onClose(): void;
  onValidate(aggr: HighlightProperties): void;
  onDelete(index: number): void;
  onClear(): void;
}

export interface HighlightProperties {
  id: string;
  label: string;
  sequence: number;
  isActive: boolean;
  typeHighlight: "line" | "column";
  backgroundColor: string;
  color: string;
  column: string;
  operator: string;
  term: string;
}

const defaultColumns = [
  { column: "resourceId", label: "resourceId", position: 0 },
  { column: "endDate", label: "endDate", position: 1 },
  { column: "tooltip", label: "tooltip", position: 2 },
  { column: "id", label: "id", position: 3 },
  { column: "type", label: "type", position: 4 },
  { column: "title", label: "title", position: 5 },
  { column: "look", label: "look", position: 6 },
  { column: "startDate", label: "startDate", position: 7 },
  { column: "tableName", label: "tableName", position: 8 }
];

const SUPPORTED_FUNCTION = [
  "=",
  "!=",
  "is null",
  "is not null",
  "like",
  "not like",
  "in",
  "not in",
  ">",
  "<",
  ">=",
  "<="
  // "contains",
  // "does not contain",
  // "regexp_like"
];

const regexLikeMemoize = memoizee(
  (str: string) => {
    return new RegExp(str.replace(/%/g, ".*"));
  },
  {
    primitive: true
  }
);

function isHighlightString(entity: Pojo, highlight: HighlightProperties) {
  const term = highlight.term;
  const entityValue = entity[highlight.column];

  switch (highlight.operator) {
    case "=":
      // eslint-disable-next-line eqeqeq
      return entityValue == term;
    case "!=":
      // eslint-disable-next-line eqeqeq
      return entityValue != term;
    case "is null":
      return entityValue == null;
    case "is not null":
      return entityValue != null;
    case "like": {
      const search = regexLikeMemoize(highlight.term);
      return entityValue != null && search.test(entityValue);
    }
    case "not like": {
      const search = regexLikeMemoize(highlight.term);
      return entityValue != null && !search.test(entityValue);
    }
    case "in": {
      const list = highlight.term.split(",");
      return list.indexOf(entityValue) !== -1;
    }
    case "not in": {
      const list = highlight.term.split(",");
      return list.indexOf(entityValue) === -1;
    }
    // // >
    // // <
    // // >=
    // // <=
    // case ">":
    //   return !isNaN(Number.parseInt(term, 10)) && entityValue > Number.parseInt(term, 10);
    // case "<":
    //   return !isNaN(Number.parseInt(term, 10)) && entityValue < Number.parseInt(term, 10);
    // case ">=":
    //   return !isNaN(Number.parseInt(term, 10)) && entityValue >= Number.parseInt(term, 10);
    // case "<=":
    //   return !isNaN(Number.parseInt(term, 10)) && entityValue <= Number.parseInt(term, 10);
    default:
      return false;
  }
}

function isHighlightDate(entity: Pojo, highlight: HighlightProperties) {
  const term = parse(highlight.term);
  const entityValue = parse(entity[highlight.column]);

  if (!isDate(term)) {
    return false;
  }

  switch (highlight.operator) {
    case "=":
      return isEqual(entityValue, term);
    case "!=":
      return !isEqual(entityValue, term);
    case "is null":
      return entityValue == null;
    case "is not null":
      return entityValue != null;
    case ">":
      return isAfter(entityValue, term);
    case "<":
      return isBefore(entityValue, term);
    case ">=":
      return isAfter(entityValue, term) || isEqual(entityValue, term);
    case "<=":
      return isBefore(entityValue, term) || isEqual(entityValue, term);

    default:
      return false;
  }
}

function isHighlightNumber(entity: Pojo, highlight: HighlightProperties) {
  const term = highlight.term;
  const entityValue = entity[highlight.column];
  switch (highlight.operator) {
    case "=":
      // eslint-disable-next-line eqeqeq
      return entityValue == term;
    case "!=":
      // eslint-disable-next-line eqeqeq
      return entityValue != term;
    case "is null":
      return entityValue == null;
    case "is not null":
      return entityValue != null;
    case ">":
      return !isNaN(Number.parseInt(term, 10)) && entityValue > Number.parseInt(term, 10);
    case "<":
      return !isNaN(Number.parseInt(term, 10)) && entityValue < Number.parseInt(term, 10);
    case ">=":
      return !isNaN(Number.parseInt(term, 10)) && entityValue >= Number.parseInt(term, 10);
    case "<=":
      return !isNaN(Number.parseInt(term, 10)) && entityValue <= Number.parseInt(term, 10);
    default:
      return false;
  }
}

export function isHighlighted(typeCompo: string, entity: Pojo, highlight: HighlightProperties) {
  try {
    switch (typeCompo) {
      case "NUMBER":
        return isHighlightNumber(entity, highlight);

      case "DATE":
        return isHighlightDate(entity, highlight);

      case "INPUT":
        return false;

      case "TEXT":
      case "LINK":
      default:
        return isHighlightString(entity, highlight);
    }
  } catch {
    return false;
  }
}

export function useHighlights() {
  const [highlights, setHighlights] = useState<HighlightProperties[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return { highlights, setHighlights, isOpen, toggle };
}

function initHighlightProperties(sequence: number): HighlightProperties {
  return {
    id: uuidv4(),
    label: "",
    sequence,
    isActive: true,
    typeHighlight: "line",
    backgroundColor: "",
    color: "",
    column: "",
    operator: "=",
    term: ""
  };
}

const COLORS_BACKGROUND = [
  "#e57373",
  "#f06292",
  "#ba68c8",
  "#9575cd",
  "#7986cb",
  "#64b5f6",
  "#4fc3f7",
  "#4dd0e1",
  "#4db6ac",
  "#81c784",
  "#aed581",
  "#dce775",
  "#fff176",
  "#ffd54f",
  "#ffb74d",
  "#ff8a65",
  "#a1887f",
  "#90a4ae"
];

const OPERATOR_BY_TYPE = {
  TEXT: ["=", "!=", "is null", "is not null", "like", "not like", "in", "not in"],
  DATE: ["=", "!=", "is null", "is not null", ">", "<", ">=", "<="],
  NUMBER: ["=", "!=", "is null", "is not null", ">", "<", ">=", "<="]
};

const HighlightDialog: FC<HighlightDialogProps> = props => {
  const [selectedHighlight, setSelectedHighlight] = useState(props.selectedHighlightId || "new");
  const [currentHighlight, setCurrentHighlight] = useState(
    initHighlightProperties(props.highlights.length)
  );

  useEffect(() => {
    if (selectedHighlight === "new") {
      setCurrentHighlight(initHighlightProperties(props.highlights.length));
    } else {
      const aggr = props.highlights.find(ag => ag.id === selectedHighlight);
      aggr && setCurrentHighlight(aggr);
    }
  }, [selectedHighlight]);

  const validColumns = useMemo(() => {
    if (props.columns.length === 0) {
      return defaultColumns;
    } else {
      return props.columns.filter(col => col.typeCompo !== "INPUT");
    }
  }, [props.columns]);

  function changeSelectedAggr(e: SyntheticEvent<HTMLSelectElement>) {
    setSelectedHighlight(e.currentTarget.value);
  }

  function onChange(e: SyntheticEvent<any>) {
    const field = e.currentTarget.name;
    const value = convertValue(e);

    setCurrentHighlight({
      ...currentHighlight,
      term: field === "column" ? "" : currentHighlight.term,
      [field]: value
    });
  }

  function onChangeColor(field: string, value: string) {
    setCurrentHighlight({
      ...currentHighlight,
      [field]: value
    });
  }

  function clear() {
    setSelectedHighlight("new");
    props.onClear();
  }

  function onValidate(e: FormEvent) {
    // on empeche le rechargement de la page
    e.preventDefault();
    // on execute la "sauvegarde" de notre nouvelle mise en évidence
    if (props.highlights.findIndex(h => h.id === currentHighlight.id) === -1) {
      setCurrentHighlight(initHighlightProperties(currentHighlight.sequence + 1));
    }
    props.onValidate(currentHighlight);
  }

  const currentColumn = props.columns.find(col => col.column === currentHighlight.column);

  const currentOperator: string[] = currentColumn
    ? OPERATOR_BY_TYPE[currentColumn.typeCompo] || SUPPORTED_FUNCTION
    : SUPPORTED_FUNCTION;

  let currentTypeInput = "text";
  if (currentColumn) {
    if (currentColumn.typeCompo === "NUMBER") {
      currentTypeInput = "number";
    }

    if (currentColumn.typeCompo === "DATE") {
      currentTypeInput = "date";
    }
  }

  return (
    <Modal
      title={
        <>
          <Trans i18nKey="commun_highlight">Mise en évidence</Trans>
          <span className="is-pulled-right">
            <button
              className="button is-text is-small"
              onClick={clear}
              disabled={props.highlights.length === 0}
            >
              <Trans i18nKey="commun_vider" />
            </button>
          </span>
        </>
      }
      onClose={props.onClose}
      hideFooter
    >
      <Field label={t("commun_highlight")} isHorizontal>
        <div className="select">
          <select value={selectedHighlight} onChange={changeSelectedAggr}>
            <option value="new">{t("commun_nouveau_mise_en_evidence")}</option>
            {props.highlights.map(highlight => {
              return (
                <option key={highlight.id} value={highlight.id}>
                  {highlight.label}
                </option>
              );
            })}
          </select>
        </div>
      </Field>
      <br />
      <form onSubmit={onValidate}>
        <Field label={t("commun_highlight_label")} isHorizontal>
          <input
            className="input"
            name="label"
            value={currentHighlight.label}
            onChange={onChange}
          />
        </Field>
        <Field label={t("commun_highlight_sequence")} isHorizontal>
          <input
            type="number"
            className="input"
            name="sequence"
            value={currentHighlight.sequence}
            onChange={onChange}
          />
        </Field>
        <Field isHorizontal>
          <label className="checkbox">
            <input
              type="checkbox"
              name="isActive"
              checked={currentHighlight.isActive}
              onChange={onChange}
            />
            {t("commun_highlight_is_active")}
          </label>
        </Field>
        <Field label={t("commun_highlight_type_highlight")} isHorizontal>
          <div className="select">
            <select name="typeHighlight" value={currentHighlight.typeHighlight} onChange={onChange}>
              <option value="column">{t("commun_highlight_type_highlight_column")}</option>
              <option value="line">{t("commun_highlight_type_highlight_line")}</option>
            </select>
          </div>
        </Field>

        <Field className="pb-5" label={t("commun_highlight_background_color")} isHorizontal>
          <ColorPicker
            colors={COLORS_BACKGROUND}
            color={currentHighlight.backgroundColor}
            width="400px"
            onChangeComplete={c => {
              onChangeColor("backgroundColor", c.hex);
            }}
          />
        </Field>
        <Field className="pb-5" label={t("commun_highlight_color")} isHorizontal>
          <ColorPicker
            color={currentHighlight.color}
            width="400px"
            onChangeComplete={c => {
              onChangeColor("color", c.hex);
            }}
          />
        </Field>

        <Field label={t("commun_highlight_condition_mise_en_evidence")}>
          <Field addons>
            <Control>
              <div className="select" style={{ maxWidth: "20rem" }}>
                <select name="column" value={currentHighlight.column} onChange={onChange}>
                  <option />
                  {validColumns.map(col => {
                    return (
                      <option key={col.column} value={col.column}>
                        {`${col.label} (${col.column})`}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Control>
            <Control>
              <div className="select">
                <select name="operator" value={currentHighlight.operator} onChange={onChange}>
                  <option />
                  {currentOperator.map(fn => {
                    return (
                      <option key={fn} value={fn}>
                        {fn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Control>
            <Control>
              <input
                type={currentTypeInput}
                className="input"
                name="term"
                value={
                  currentTypeInput === "date"
                    ? format(currentHighlight.term, "YYYY-MM-DD") || ""
                    : currentHighlight.term
                }
                onChange={onChange}
              />
            </Control>
          </Field>
        </Field>
        <div className="field is-grouped">
          <div className="control">
            <input type="submit" className="button is-primary" value={t("commun_valider")} />
          </div>
          <div className="control">
            <button
              className="button"
              disabled={props.highlights.findIndex(h => h.id === currentHighlight.id) === -1}
              onClick={e => {
                e.preventDefault();
                props.onDelete(props.highlights.findIndex(h => h.id === currentHighlight.id));
              }}
            >
              {t("commun_supprimer")}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default HighlightDialog;
