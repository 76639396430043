const globals = {
  SENTRY_URL: import.meta.env.VITE_SENTRY_URL,
  URL_DATA: import.meta.env.VITE_REST_URL,
  URL_ADN: import.meta.env.VITE_REST_URL_ADN,
  URL_UTILS: import.meta.env.VITE_REST_URL_UTILS,
  URL_DOWNLOAD: import.meta.env.VITE_REST_URL_DOWNLOAD,
  URL_DOWNLOAD_VIA_GED: import.meta.env.VITE_REST_URL_DOWNLOAD_VIA_GED,
  URL_DOWNLOAD_VIA_UTILS: import.meta.env.VITE_REST_URL_DOWNLOAD_VIA_UTILS,
  URL_TEMPLATE_SERVER: import.meta.env.VITE_URL_TEMPLATE_SERVER,
  URL_TEMPLATE_SERVEUR_DEV: import.meta.env.VITE_URL_TEMPLATE_SERVER_DEV,
  OAUTH_URL: import.meta.env.VITE_OAUTH_URL,
  REALM: import.meta.env.VITE_OAUTH_REALM || "octal",
  CLIENT_ID: "app:front-universe",
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  NODE_ENV: import.meta.env.NODE_ENV,
  IS_PRODUCTION: import.meta.env.NODE_ENV === "production",
  PUBLIC_URL: import.meta.env.BASE_URL || "/",
  LOADER_TIME_TRIGGER: 1000,
  LOADER_GALAXY_TIME_TRIGGER: 2000,
  DATATABLE_PRE_RECORD_ENTITY_CACHE: 5 * 60 * 1000
};

export const SENTRY_URL = () => globals.SENTRY_URL;
export const URL_DATA = () => globals.URL_DATA;
export const URL_ADN = () => globals.URL_ADN;
export const URL_UTILS = () => globals.URL_UTILS;
export const URL_DOWNLOAD = () => globals.URL_DOWNLOAD;
export const URL_DOWNLOAD_VIA_GED = () => globals.URL_DOWNLOAD_VIA_GED;
export const URL_DOWNLOAD_VIA_UTILS = () => globals.URL_DOWNLOAD_VIA_UTILS;
export const URL_TEMPLATE_SERVER = () => getTemplateServerUrl();

export const OAUTH_URL = () => globals.OAUTH_URL;
export const REALM = () => globals.REALM;
export const CLIENT_ID = () => globals.CLIENT_ID;

export const ENVIRONMENT = () => globals.ENVIRONMENT;

export const NODE_ENV = () => globals.NODE_ENV;

export const IS_PRODUCTION = () => globals.NODE_ENV === "production";

export const PUBLIC_URL = () => globals.PUBLIC_URL;

export const LOADER_TIME_TRIGGER = () => globals.LOADER_TIME_TRIGGER;
export const LOADER_GALAXY_TIME_TRIGGER = () => globals.LOADER_GALAXY_TIME_TRIGGER;

// maximum de 5 minutes pour le cache du pre-record par datatable
export const DATATABLE_PRE_RECORD_ENTITY_CACHE = () => globals.DATATABLE_PRE_RECORD_ENTITY_CACHE;

const SERVER_TEMPLATE_URL_KEY = "__template_server_ui_url___";

export function switchTemplateServerUrl() {
  // if (import.meta.env.PROD) return;
  if (!globals.URL_TEMPLATE_SERVEUR_DEV) return;

  const url = localStorage.getItem(SERVER_TEMPLATE_URL_KEY);

  if (url === globals.URL_TEMPLATE_SERVEUR_DEV) {
    localStorage.setItem(SERVER_TEMPLATE_URL_KEY, globals.URL_TEMPLATE_SERVER || "");
  } else {
    localStorage.setItem(SERVER_TEMPLATE_URL_KEY, globals.URL_TEMPLATE_SERVEUR_DEV || "");
  }

  window.location.reload();
}

export function getTemplateServerUrl() {
  let url: string | null = localStorage.getItem(SERVER_TEMPLATE_URL_KEY);

  return url ?? globals.URL_TEMPLATE_SERVER;
}

export function getTemplateServerLabel() {
  const url = localStorage.getItem(SERVER_TEMPLATE_URL_KEY);

  if (url === globals.URL_TEMPLATE_SERVEUR_DEV) {
    return "arrêter le travail local";
  } else {
    return "passer en local";
  }
}
