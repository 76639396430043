import React, { FC, useMemo, useEffect, useState } from "react";

import { Pojo } from "types/Galaxy";
import { findAll, findView } from "api";
import { GSBuilder, initRsqlCriteria } from "utils/query.utils";
import { useDirtyGalaxy } from "layout/Dirty";

export interface ProcessusContextProps {
  sjmoCode: string;
  tableName: string;
  navigationUrl?: string;
  selected?: Pojo[];
  arboCtrlKey?: string;
  isDirty(): boolean;
  onAfterSaveProcess?(): void;
}

export const ProcessusContext = React.createContext<ProcessusContextProps | null>(null);

type SelectedProps = string | Pojo | string[] | Pojo[];

export const ProcessusProvider: FC<React.PropsWithChildren<{
  sjmoCode: string;
  tableName: string;
  selected?: SelectedProps;
  navigationUrl?: string;
  arboCtrlKey?: string;
  entityFromView?: boolean;
  onAfterSaveProcess?(): void;
}>> = ({ children, selected, ...props }) => {
  const [fetchedEntities, setFetchedEntities] = useState<Pojo[]>([]);
  const selectedEntities = useMemo(() => {
    if (selected == null || selected === undefined) return undefined;
    else if (Array.isArray(selected)) return selected;
    else return [selected];
  }, [selected]);

  const useInternalEntities = useMemo(() => {
    return selectedEntities?.every(it => typeof it === "string") ?? false;
  }, [selectedEntities]);

  const { getDirty } = useDirtyGalaxy();

  useEffect(() => {
    if (!useInternalEntities) return;
    if (selectedEntities === undefined || selectedEntities.length === 0) return;

    const filter = GSBuilder.toFilter(GSBuilder.Comparison("id", "OPER_IN", selectedEntities));
    const rsql = initRsqlCriteria();
    rsql.filters.and(filter);
    if (props.entityFromView) {
      findView({ tableName: props.tableName, filter: rsql.build(), size: false })
        .then(res => {
          setFetchedEntities(res.data.data);
        })
        .catch(() => {
          setFetchedEntities([]);
        });
    } else {
      findAll({
        tableName: props.tableName,
        filter: rsql.build(),
        size: false
      })
        .then(res => {
          setFetchedEntities(res.data.data);
        })
        .catch(() => {
          setFetchedEntities([]);
        });
    }
  }, [props.entityFromView, props.tableName, selectedEntities, useInternalEntities]);

  const contextValue = useMemo(
    () => ({
      ...props,
      isDirty: () => getDirty(props.sjmoCode),
      selected: useInternalEntities ? fetchedEntities : (selectedEntities as Pojo[])
    }),
    [fetchedEntities, getDirty, props, selectedEntities, useInternalEntities]
  );
  return <ProcessusContext.Provider value={contextValue}>{children}</ProcessusContext.Provider>;
};
