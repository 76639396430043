import { apiAdn, getUIContext } from "api/common";
import { Pojo } from "types/Galaxy";

interface SyjProcessusComposite {
  id: string;
  label: string;
  steps: Pojo[];
}

export function createPanelsDuplicationOrExtract(
  sjmoCode: string,
  panel: string,
  newmodule: string,
  preRecordPanel: Pojo,
  sqlActive: string
) {
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/duplicationPanels/new?${params}&includeJoinParent=true`, {
    panel: panel,
    newmodule: newmodule,
    preRecordPanel: preRecordPanel,
    sqlActive: sqlActive
  });
}

export function createPanelsExtract(sjmoCode: string, panel: string) {
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/extractionSQLPanel/new?${params}&includeJoinParent=true`, {
    panel: panel
  });
}

export function createDatatableExtract(sjmoCode: string, datatableId: string) {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(`/admin/extractionSQLDT/new?${params}&includeJoinParent=true`, {
    datatableId: datatableId
  });
}

export function createFocusDatatableDuplicationOrExtract(
  sjmoCode: string,
  syjFocusSelected: string | undefined,
  ctrlKey: string,
  resourceKey: string,
  syjModuleSelected: string | undefined,
  sqlActive: string,
  privilegie: boolean,
  choiceDuplicationTo: string,
  roleIdSelected: string | null,
  personnelSelected: string | null
) {
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/duplicationFocusDatatable/new?${params}&includeJoinParent=true`, {
    syjFocusSelected: syjFocusSelected,
    ctrlKey: ctrlKey,
    resourceKey: resourceKey,
    syjModuleSelected: syjModuleSelected,
    sqlActive: sqlActive,
    privilegie: privilegie,
    choiceDuplicationTo: choiceDuplicationTo,
    roleIdSelected: roleIdSelected,
    personnelSelected: personnelSelected
  });
}

export function createFocusCreatorDuplicationOrExtract(
  sjmoCode: string,
  syjFocusSelected: string,
  resourceKey: string,
  syjModuleSelected: string,
  sqlActive: string
) {
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/duplicationFocusCreator/new?${params}&includeJoinParent=true`, {
    syjFocusSelected: syjFocusSelected,
    syjModuleSelected: syjModuleSelected,
    sqlActive: sqlActive,
    resourceKey: resourceKey
  });
}

export function createFocusDuplicationOrExtract(
  sjmoCode: string,
  syjFocusSelected: string,
  syjModuleSelected: string,
  sqlActive: string
) {
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/extractionFocussql/new?${params}&includeJoinParent=true`, {
    syjFocusSelected: syjFocusSelected,
    syjModuleSelected: syjModuleSelected,
    sqlActive: sqlActive
  });
}

export function createIRDuplicationOrExtract(sjmoCode: string, selectedIR: String) {
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/extractionSQLIR/new?${params}&includeJoinParent=true`, {
    selectedIR: selectedIR
  });
}

export function createProcessusExtract(sjmoCode: string, processusCompositeId: string) {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(`/admin/extractionSQLProcessus/new?${params}&includeJoinParent=true`, {
    processusCompositeId: processusCompositeId
  });
}

export function createProcessusDuplicationOrExtract(
  sjmoCode: string,
  processusComposite: SyjProcessusComposite,
  processusType: String,
  sqlActive: string,
  syjTableSelected: string
) {
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/duplicationProcessus/new?${params}&includeJoinParent=true`, {
    processusComposite: processusComposite,
    processusType: processusType,
    sqlActive: sqlActive,
    syjTableSelected: syjTableSelected
  });
}
