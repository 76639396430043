import React, { FC, useEffect, useState, useMemo } from "react";
import { Menu } from "composants/DropDown/Menu";
import { Avatar } from "composants/Avatar";
import { CurrentUser } from "composants/CurrentUser";
import SelectLang from "composants/userSettings/SelectLang";
import { ModeDebug } from "composants/userSettings/ModeDebug";
import { SuperUser } from "composants/userSettings/SuperUser";
import { Fa } from "composants/Icon";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ReducerState } from "reducers";
import { resetPackage } from "actions/processus";

import { Link } from "react-router-dom";

import { Version } from "./Version";
import SelectSize, { useVariableFontProperties } from "composants/userSettings/SelectSize";
import { disconnect, getAccountURI } from "auth";
import { disablePlugins, useAnalyticsPluginsActive, enablePlugins } from "tracking";
import SysDomaineAndLabel from "composants/select/SysDomaineAndLabel";
import { useTraduction } from "composants/GetTraduction";
import { convertValue } from "utils/entities.utils";
import { switchTemplateServerUrl, getTemplateServerLabel } from "customGlobal";
import { resetDatabasePooling } from "api/resetPool";
import toaster from "composants/toaster/toaster";

function selectLangAndSuperUser({ userSettings }: ReducerState) {
  return { lang: userSettings.lang, adminLevel: userSettings.adminLevel };
}

function selectSocietes(state: ReducerState) {
  return {
    current: state.userSettings.societeEnCours,
    list: state.userSettings.societeDisponible
  };
}

const SelectSocietes: FC = props => {
  const [trad] = useTraduction("personnel_societeId");
  const { current, list } = useSelector(selectSocietes);
  const [val, setVal] = useState(
    sessionStorage.getItem("societeEnCours") ? sessionStorage.getItem("societeEnCours") : current
  );

  useEffect(() => {
    sessionStorage.setItem("societeEnCours", val ?? "");
  }, [val]);

  const choices = useMemo(() => {
    const defaultSociete = [{ label: current, value: current }];
    const additionalSociete = list
      .filter(el => el !== current)
      .map(el => ({ label: el, value: el }));

    return defaultSociete.concat(additionalSociete);
  }, [current, list]);

  return (
    <SysDomaineAndLabel
      id="changeSociete"
      label={trad}
      sysDomaineChoices={choices}
      value={val}
      onChange={e => setVal(convertValue(e))}
      required
      disabled={choices.length <= 1}
    />
  );
};

export const Settings: FC = () => {
  const { lang, adminLevel } = useSelector(selectLangAndSuperUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isTrackingUserActive = useAnalyticsPluginsActive();
  const [fontProperty, setFontProperty] = useVariableFontProperties();

  function togglePlugins() {
    if (isTrackingUserActive) disablePlugins();
    else enablePlugins();
  }

  function onClickResetPackage() {
    dispatch(resetPackage());
  }

  function onClickResetPool() {
    resetDatabasePooling()
      .then(() => {
        toaster.success(t("commun_resetTermine"));
      })
      .catch(() => {
        toaster.error(t("commun_resetErreur"));
      });
  }

  return (
    <Menu className="is-right" autoclose>
      <Menu.Button>
        <Avatar />
      </Menu.Button>
      <Menu.Content style={{ minWidth: "15rem" }}>
        <Menu.Item>
          <CurrentUser />
        </Menu.Item>
        <Menu.Separator />
        <Menu.Item>
          <SelectLang selectedLang={lang} />
        </Menu.Item>
        <Menu.Item>
          <SelectSocietes />
        </Menu.Item>
        {adminLevel >= 1 && (
          <>
            <Menu.Item>
              <ModeDebug />
            </Menu.Item>
            <Menu.Item>
              <SuperUser />
            </Menu.Item>
            <Menu.Item as="a" onClick={onClickResetPackage}>
              <span className="icon">
                <Fa icon={["fal", "sync"]} fixedWidth />
              </span>
              <Trans i18nKey="commun_reset_package" />
            </Menu.Item>
            {adminLevel === 3 && (
              <Menu.Item as="a" onClick={onClickResetPool}>
                <span className="icon">
                  <Fa icon={["fal", "database"]} fixedWidth />
                </span>
                <Trans i18nKey="commun_resetDatabasePooling" />
              </Menu.Item>
            )}
            <Menu.Item as="a" onClick={togglePlugins}>
              <span className="icon">
                <Fa icon={["fal", "analytics"]} fixedWidth />
              </span>
              <Trans
                i18nKey={
                  isTrackingUserActive ? "commun_desactiver_tracking" : "commun_activer_tracking"
                }
              />
            </Menu.Item>
          </>
        )}
        <Menu.Item as="a" href={getAccountURI()}>
          <span className="icon">
            <Fa icon={["fal", "sliders-h"]} fixedWidth />
          </span>
          <Trans i18nKey="commun_parametrage_compte" />
        </Menu.Item>{" "}
        <Menu.Separator />
        {adminLevel >= 1 && (
          <>
            <Menu.Item as={Link} to="/admin/client">
              <span className="icon">
                <Fa icon={["fal", "cog"]} fixedWidth />
              </span>
              <Trans i18nKey="commun_administration_client" />
            </Menu.Item>
            {/* <Menu.Item>
              <SelectSize fontProperty={fontProperty} setFontProperty={setFontProperty} />
            </Menu.Item> */}
          </>
        )}
        {adminLevel === 3 && (
          <>
            <Menu.Item as={Link} to="/admin/integrateur">
              <span className="icon">
                <Fa icon={["fal", "cog"]} fixedWidth />
              </span>
              <Trans i18nKey="commun_administration_integrateur" />
            </Menu.Item>
            <Menu.Item>
              <SelectSize fontProperty={fontProperty} setFontProperty={setFontProperty} />
            </Menu.Item>
            <Menu.Separator />
          </>
        )}
        {adminLevel === 3 && (
          <>
            <strong>Choisir le serveur de template</strong>
            <Menu.Item
              as="a"
              onClick={() => {
                switchTemplateServerUrl();
              }}
            >
              {getTemplateServerLabel()}
            </Menu.Item>
          </>
        )}
        <Menu.Separator />
        <Menu.Item>
          <Version />
        </Menu.Item>
        <Menu.Separator />
        <Menu.Item as="a" className="pr-8" onClick={() => disconnect()}>
          <span className="icon">
            <Fa icon={["fal", "power-off"]} fixedWidth />
          </span>
          <span>
            <Trans i18nKey="commun_deconnexion" />
          </span>
        </Menu.Item>
      </Menu.Content>
    </Menu>
  );
};
