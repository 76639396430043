import { Button, ButtonGroup, ButtonProps, Menu } from "@axin-org/comet";
import { Fa } from "composants/Icon";
import { useInfoProcessus } from "features/processus/processusManager";
import { useRef } from "react";
import { Trans } from "react-i18next";
import { track } from "tracking";
import { tw } from "twind";
import { ProcessusDefinition } from "types/Processus";
import ConfirmationModal from "./ConfirmationModal";
import DelayProcessModal from "./DelayProcessModal";
import { useProcessus } from "./useProcessus";

function ProcessusLoading(props: { id: string }) {
  const [processusState] = useInfoProcessus(props.id);

  return !processusState.matches("done") &&
    !processusState.matches("wait") &&
    !processusState.matches("error") ? (
    <span className="icon is-small">
      <Fa icon="spinner" spin />
    </span>
  ) : null;
}

export function ProcessusButton({
  definition,
  title,
  className,
  variant = "light",
  intent = "secondary",
  size = "xs",
  disabled,
  onlyDefault = false,
  children
}: {
  definition: ProcessusDefinition;
  className?: string;
  title?: string;
  variant?: ButtonProps["variant"];
  intent?: ButtonProps["intent"];
  size?: ButtonProps["size"];
  onlyDefault?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}) {
  const containerRef = useRef<HTMLButtonElement | null>(null);
  const processus = useProcessus(definition);

  const modals = (
    <>
      {processus.modal.confirm.show && processus.modal.position && (
        <ConfirmationModal
          id={definition.id}
          launchProcess={processus.launch}
          close={() => {
            processus.modal.confirm.set(false);
            track("processus::modal::cancel");
          }}
          top={processus.modal.position.x}
          left={processus.modal.position.y}
          buttonRef={containerRef}
        />
      )}
      {processus.modal.calendar.show && (
        <DelayProcessModal
          sjmoCode={processus.context.sjmoCode}
          compositeID={definition.id}
          type={definition.type}
          editionType={processus.editionDefault}
          selected={processus.context.selected ?? []}
          advanced={
            (definition.type === "traitement" && definition.isAdvanced) ||
            (definition.type === "edition" && definition.isAdvanced)
          }
          needEntity={definition.needEntity}
          isOneByOne={definition.isOneByOne}
          label={definition.label}
          onClose={() => processus.modal.calendar.set(false)}
          callback={processus.context.onAfterSaveProcess}
        />
      )}
    </>
  );

  if (onlyDefault) {
    return (
      <>
        <Button
          className={tw(className)}
          title={title}
          variant={variant}
          intent={intent}
          size={size}
          onClick={processus.button.onClick}
          disabled={disabled || processus.button.isDisabled}
          ref={containerRef}
        >
          {processus.hasProcessusStateSpawned && (
            <ProcessusLoading id={processus.stableProcessusId} />
          )}
          {children}
        </Button>
        {modals}
      </>
    );
  }

  return (
    <>
      <ButtonGroup>
        <Button
          className={tw(className)}
          title={title}
          variant={variant}
          intent={intent}
          size={size}
          onClick={processus.button.onClick}
          disabled={disabled || processus.button.isDisabled}
          ref={containerRef}
        >
          {processus.hasProcessusStateSpawned && (
            <ProcessusLoading id={processus.stableProcessusId} />
          )}
          {children}
        </Button>
        {definition.type === "edition" && (
          <Menu>
            <Menu.Trigger>
              <Button
                className="rounded-l-none"
                variant={variant}
                intent={intent}
                size={size}
                disabled={disabled || processus.button.isDisabled}
              >
                <Fa icon={["fas", "chevron-down"]} />
              </Button>
            </Menu.Trigger>
            <Menu.Content align="start">
              <Menu.Item
                onSelect={() => processus.launch("apercu")}
                disabled={disabled || !definition.apercu}
              >
                <span className={tw("inline-flex justify-center items-center w-4 h-4")}>
                  <Fa className={tw("text-blue-800")} icon={["fas", "search"]} size="sm" />
                </span>
                <Trans i18nKey="commun_apercu" />
              </Menu.Item>
              <Menu.Item
                onSelect={() => processus.launch("rapide")}
                disabled={disabled || !definition.rapide}
              >
                <span className={tw("inline-flex justify-center items-center w-4 h-4")}>
                  <Fa className={tw("text-blue-800")} icon={["fas", "bolt"]} size="sm" />
                </span>
                <Trans i18nKey="commun_rapide" />
              </Menu.Item>
            </Menu.Content>
          </Menu>
        )}
      </ButtonGroup>
      {modals}
    </>
  );
}
