import React, { FC, useMemo } from "react";

import { Dropdown, DropdownButton, DropdownMenuRaw } from "composants/DropDown/Dropdown";
import { Fa } from "composants/Icon";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { ReducerState } from "reducers";
import { AppGalaxyInfo } from "types/Galaxy";
import { Link } from "react-router-dom";

import "./LeGrosMenu.css";
import { toggleAppFavoris } from "api";
import { galaxyMenuFavorisSuccess } from "actions/galaxies.action";
import { findMap } from "utils/entities.utils";
import { IconName } from "@fortawesome/pro-solid-svg-icons";

const getSelectorGalaxiesInfos = (state: ReducerState) => {
  return {
    galaxies: state.app.galaxies,
    mostUsed: state.app.mostUsed,
    favoris: state.app.favoris
  };
};

const LeGrosMenuInternal: FC = props => {
  const galaxiesInfos = useSelector(getSelectorGalaxiesInfos);
  const dispatch = useDispatch();

  function toggleFavoris(code: string) {
    toggleAppFavoris(code, null).then(res => {
      dispatch(galaxyMenuFavorisSuccess(res.data));
    });
  }

  const home = useMemo(() => {
    return findMap(galaxiesInfos.galaxies, el => el.type === "HOME");
  }, [galaxiesInfos.galaxies]);

  const univers = useMemo(() => {
    let appInfosUnivers: AppGalaxyInfo[] = [];
    const keys = Object.keys(galaxiesInfos.galaxies);

    for (let key of keys) {
      if (galaxiesInfos.galaxies[key].type === "UNIVERS") {
        appInfosUnivers.push(galaxiesInfos.galaxies[key]);
      }
    }
    return appInfosUnivers;
  }, [galaxiesInfos.galaxies]);

  return (
    <Dropdown autoclose>
      <DropdownButton
        render={param => {
          return (
            <button ref={param.buttonRef} className="is-size-3" onClick={param.onOpen} title="menu">
              <span className="icon">
                <Fa icon="grip-vertical" className="has-text-white" transform="down-1" />
              </span>
            </button>
          );
        }}
      />
      <DropdownMenuRaw
        backgroundClass="gros-menu-background"
        style={{ zIndex: 50 }}
        render={({ onClose }) => {
          return (
            <>
              <div className="box" style={{ minWidth: "25rem", maxWidth: "50rem", padding: 0 }}>
                <section className="p-5">
                  <p className="title is-5">
                    <Trans i18nKey="commun_univers">Univers</Trans>
                  </p>
                  {univers.length > 0 ? (
                    <ul className="columns is-multiline">
                      {home && (
                        <li className="column is-4" key={home.code}>
                          <Link
                            className="flex flex-col has-text-centered"
                            to={`page/${home.code}` || "#"}
                            onClick={onClose}
                          >
                            <span
                              className="icon is-large"
                              style={{
                                fontSize: "1.5rem",
                                backgroundColor: "hsl(205, 79%, 92%)",
                                borderRadius: 9999,
                                padding: 5,
                                alignSelf: "center",
                                boxShadow: "0 2px 4px 0 hsla(205, 80%, 83%, 1)"
                              }}
                            >
                              <Fa icon={["fal", "home"]} fixedWidth />
                            </span>

                            <div className="title is-6 mt-8">{home.label}</div>
                          </Link>
                        </li>
                      )}
                      {univers.map(univ => (
                        <li className="column is-4" key={univ.code}>
                          <Link
                            className="flex flex-col has-text-centered"
                            to={`/page/${univ.code}` || "#"}
                            onClick={onClose}
                          >
                            <span
                              className="icon is-large"
                              style={{
                                fontSize: "1.5rem",
                                backgroundColor: "hsl(205, 79%, 92%)",
                                borderRadius: 9999,
                                padding: 5,
                                alignSelf: "center",
                                boxShadow: "0 2px 4px 0 hsla(205, 80%, 83%, 1)"
                              }}
                            >
                              <Fa
                                icon={[
                                  "fal",
                                  (univ.icon
                                    ? univ.icon.startsWith("fa-")
                                      ? univ.icon.replace(/fa-/g, "")
                                      : univ.icon
                                    : "stars") as IconName
                                ]}
                                fixedWidth
                              />
                            </span>

                            <div className="title is-6 mt-8">{univ.label}</div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="subtitle is-6">
                      <Trans i18nKey="commun_aucun_univers">Aucun univers de paramétré</Trans>
                    </p>
                  )}
                </section>
                <div
                  className="has-background-blue-alt"
                  style={{ boxShadow: "inset 1px 4px 9px -6px hsla(0, 0%, 0%, 0.18)" }}
                >
                  <section className="p-5">
                    <p className="title is-5">
                      <Trans i18nKey="commun_favoris">Favoris</Trans>
                    </p>
                    <ul className="tags">
                      {galaxiesInfos.favoris.map(fav => (
                        <li key={fav.code} className="tag is-white">
                          <Link
                            className="favoris-item is-uppercase"
                            to={`/page/${fav.code}` || "#"}
                            onClick={onClose}
                          >
                            {fav.label}
                          </Link>
                          <button
                            className="delete is-small"
                            onClick={() => toggleFavoris(fav.code)}
                          />
                        </li>
                      ))}
                    </ul>
                  </section>
                </div>
                <section className="p-5">
                  <p className="title is-5">
                    <Trans i18nKey="commun_suggestion">Suggestion</Trans>
                  </p>
                  <ul className="tags" style={{ maxWidth: 500 }}>
                    {galaxiesInfos.mostUsed
                      .filter(
                        galaxy => !galaxiesInfos.favoris.some(fav => fav.code === galaxy.code)
                      )
                      .map(galaxy => (
                        <li key={galaxy.code} className="tag is-blue-alt">
                          <Link
                            className="favoris-item is-uppercase"
                            to={`/page/${galaxy.code}` || "#"}
                            onClick={onClose}
                          >
                            {galaxy.label}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </section>
              </div>
            </>
          );
        }}
      />
    </Dropdown>
  );
};

export const LeGrosMenu = LeGrosMenuInternal;
