import React, { FC } from "react";
import { useState } from "react";
import { convertValue } from "utils/entities.utils";
import Modal from "composants/Modal/Modal";
import { useRegisterProcessus, useStableProcessusId } from "features/processus/processusManager";
import { useCallback } from "react";
import { Pojo } from "types/Galaxy";
import { ProcessusDefinitionNature } from "types/Processus";
import Input from "composants/input/Input";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { openAdvanced } from "./useProcessus";
import { useNavigate } from "react-router-dom";

interface DelayProcessModalProps {
  sjmoCode: string;
  compositeID: string;
  selected: Pojo[];
  type: ProcessusDefinitionNature;
  editionType: "rapide" | "apercu" | undefined;
  advanced: boolean;
  label: string;
  needEntity: boolean;
  isOneByOne: boolean;
  onClose(): void;
  callback?: () => void;
}

const DelayProcessModal: FC<DelayProcessModalProps> = props => {
  const [t] = useTranslation();
  const [date, setDate] = useState<string>();
  const [
    managerState,
    { register: registerProcessus, reset: resetProcessus }
  ] = useRegisterProcessus();
  const stableID = useStableProcessusId(props.compositeID, props.selected, undefined);
  const navigate = useNavigate();

  const machineId = managerState.context.info[stableID];
  const currentProcessusMachine = managerState.context.processus[machineId ?? ""];
  const hasProcessusStateSpawned =
    currentProcessusMachine !== undefined &&
    !currentProcessusMachine.getSnapshot().matches("error") &&
    !currentProcessusMachine.getSnapshot().matches("done");

  useEffect(() => {
    if (hasProcessusStateSpawned) {
      props.onClose();
    }
  }, [hasProcessusStateSpawned, props]);

  const launchProcess = useCallback(() => {
    if (date) {
      if (currentProcessusMachine !== undefined) {
        const snapshot = currentProcessusMachine.getSnapshot();

        if (snapshot.matches("error")) {
          resetProcessus(props.compositeID, props.selected);
        } else if (!snapshot.matches("done")) {
          return;
        }
      }

      if (
        (props.type === "traitement" && props.advanced) ||
        (props.type === "edition" && props.advanced)
      ) {
        openAdvanced(
          navigate,
          props.sjmoCode,
          props.compositeID,
          "",
          props.type,
          props.selected,
          date,
          props.needEntity,
          props.isOneByOne,
          props.label,
          props.editionType,
          props.callback
        );
        props.onClose();
        return;
      }

      if (props.isOneByOne && props.needEntity) {
        const selectedItems = props.selected ? props.selected : [];
        for (let index = 0; index < selectedItems.length; index++) {
          const element = selectedItems[index];
          registerProcessus(
            {
              module: props.sjmoCode,
              compositeID: props.compositeID,
              type: props.type,
              selected: [element],
              executeAt: date,
              editionType: props.editionType,
              label: props.label
            },
            props.callback
          );
        }
        // On force un close après les registers par ce qu'il a du mal avec la boucle
        props.onClose();
      } else {
        registerProcessus(
          {
            module: props.sjmoCode,
            compositeID: props.compositeID,
            type: props.type,
            selected: props.needEntity && props.selected ? props.selected : [],
            executeAt: date,
            editionType: props.editionType,
            label: props.label
          },
          props.callback
        );
      }
    }
  }, [currentProcessusMachine, date, navigate, props, registerProcessus, resetProcessus]);

  return (
    <Modal
      title={t("commun_choisissez_heure_lancement")}
      onClose={props.onClose}
      onValidate={launchProcess}
    >
      <Input type="time" value={date} onChange={e => setDate(convertValue(e))} id="pickAnHour" />
    </Modal>
  );
};

export default DelayProcessModal;
