import React, { FC, useMemo, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Select from "react-select/async";
import { AppGalaxyInfo, AppGalaxyInfoType } from "types/Galaxy";
import { OnChangeValue, GroupBase, StylesConfig } from "react-select";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";

const selectStyles: StylesConfig = {
  container: base => ({
    ...base,
    width: "15rem",
    zIndex: 10
  })
};

export const SearchGalaxies: FC<{ galaxies: Record<string, AppGalaxyInfo> }> = props => {
  // const [value, setValue] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fuseRef = useRef<Fuse<AppGalaxyInfo> | null>(null);

  const getFuse = useCallback(function getFuse() {
    if (!fuseRef.current) {
      fuseRef.current = new Fuse<AppGalaxyInfo>([], {
        shouldSort: true,
        threshold: 0.33,

        keys: [
          {
            name: "label",
            weight: 0.8
          },
          { name: "code", weight: 0.2 }
        ]
      });
    }
    return fuseRef.current;
  }, []);

  const galaxiesList = useMemo(() => {
    const validGalaxies = Object.keys(props.galaxies).map(key => props.galaxies[key]);
    const sortedGalaxies = validGalaxies.slice().sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return sortedGalaxies;
  }, [props.galaxies]);

  useEffect(() => {
    const fuse = getFuse();
    fuse.setCollection(galaxiesList);
  }, [getFuse, galaxiesList]);

  const filterGalaxies = useCallback(
    function filterGalaxies(inputValue: string) {
      const fuse = getFuse();

      let list: AppGalaxyInfo[];
      if (inputValue === "") {
        list = galaxiesList;
      } else {
        list = fuse.search(inputValue).map(r => r.item);
        list.sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();
          return nameA.localeCompare(nameB);
        });
      }

      const reducedGalaxies: Record<AppGalaxyInfoType | "OTHERS", GroupBase<AppGalaxyInfo>> = {
        GALAXY: {
          options: [],
          label: "Galaxie"
        },
        HOME: {
          options: [],
          label: "Accueil"
        },
        KANBAN: {
          options: [],
          label: "Kanban"
        },
        UNIVERS: {
          options: [],
          label: "Univers"
        },
        TIMELINE: {
          options: [],
          label: "Scheduler"
        },
        CALENDAR: {
          options: [],
          label: "Calendar"
        },

        OTHERS: {
          options: [],
          label: "Autres"
        }
      };

      for (let galaxy of list) {
        const validType: AppGalaxyInfoType | "OTHERS" =
          galaxy.type === "HOME" ? "UNIVERS" : galaxy.type || "OTHERS";
        if (reducedGalaxies[validType]) {
          (reducedGalaxies[validType].options as any).push(galaxy);
        }
      }
      return [
        reducedGalaxies.UNIVERS,
        reducedGalaxies.GALAXY,
        reducedGalaxies.OTHERS,
        reducedGalaxies.KANBAN,
        reducedGalaxies.CALENDAR,
        reducedGalaxies.TIMELINE
      ];
    },
    [galaxiesList, getFuse]
  );

  const defaultGalaxies = useMemo(() => {
    return filterGalaxies("");
  }, [filterGalaxies]);

  function filterGalaxiesCallback(inputValue: string, callback: (param: any) => void) {
    const galaxies = filterGalaxies(inputValue);
    callback(galaxies);
  }

  return (
    <Select
      placeholder={t("commun_recherche")}
      isClearable
      closeMenuOnSelect
      defaultOptions={defaultGalaxies as any}
      loadOptions={filterGalaxiesCallback}
      onChange={(newValue: OnChangeValue<AppGalaxyInfo, false>) => {
        if (newValue) {
          const galaxy = newValue as AppGalaxyInfo;
          galaxy.code && navigate(`/page/${galaxy.code}`);
        }
      }}
      styles={selectStyles}
      getOptionLabel={(item: AppGalaxyInfo) => item.label}
      getOptionValue={(item: AppGalaxyInfo) => item.code}
      formatGroupLabel={item => item.label}
    />
  );
};
