import React, { useState, useEffect, FC, useReducer, useCallback, useRef, useMemo } from "react";
import { findAll } from "api";
import { Row, Col } from "composants/Layout";
import { Pojo } from "types/Galaxy";
import Modal from "composants/Modal/Modal";
import { initRsqlCriteria } from "utils/query.utils";
import { IndexRange } from "react-virtualized";
import CheckBoxAndLabel from "composants/checkbox/CheckBoxAndLabel";
import InputTextAndLabel from "composants/input/InputTextAndLabel";
import { getUserLang } from "utils/network.utils";
import { convertValue, listToMap } from "utils/entities.utils";
import { RSQLFilterExpression, Operators } from "rsql-criteria-typescript";
import produce from "immer";
import { newIRColumnTrad, updateIRColumnTrad } from "api/adminInteractiveReport";
import { ActionTypeData } from "reducers/Action";
import ListSelection from "composants/list/ListModule";

interface ModalUpdateColumnLabelProps {
  column: Pojo | null;
  onClose(): void;
  updateListWithColumn(pojo: Pojo | null): void;
}

type AllActionDataSource = ActionTypeData<
  "loadData",
  { data: Pojo[]; first: number; reset: boolean }
>;

function reducer(state: Record<string, Pojo | undefined>, action: AllActionDataSource) {
  switch (action.type) {
    case "loadData":
      if (action.payload.reset) {
        return listToMap(action.payload.data, action.payload.first);
      } else {
        return { ...state, ...listToMap(action.payload.data, action.payload.first) };
      }
    default:
      return state;
  }
}

const ModalUpdateColumnLabel: FC<ModalUpdateColumnLabelProps> = props => {
  const [createNewTrad, setCreateNewTrad] = useState<boolean>(false);
  const [newTrad, setNewTrad] = useState<string>("");
  const [syjIRColumn, setSyjIRColumn] = useState<Pojo | null>(null);

  const [dataSource, dispatch] = useReducer(reducer, {});
  const [selectedDataSource, setSelectedDataSource] = useState<Pojo | null>(null);
  const [rowCount, setRowCount] = useState<number>(0);
  const listTradRef = useRef<ListSelection | null>(null);
  const [tradSearch, setTradSearch] = useState<string>("");

  const rsql = initRsqlCriteria();
  rsql.filters.and(new RSQLFilterExpression("sjrbLocale", Operators.Equal, getUserLang()));

  const rsqlFilter = useMemo(() => {
    if (tradSearch !== "") {
      rsql.filters.and(
        new RSQLFilterExpression("sjrbResourceKey", Operators.Like, tradSearch + "%")
      );
      rsql.filters.or(
        new RSQLFilterExpression("sjrbResourceValue", Operators.Like, tradSearch + "%")
      );
    }

    return tradSearch !== "" ? rsql.build() : undefined;
  }, [tradSearch]);

  const loadData = useCallback(function loadData(
    first: number,
    size: number,
    callback?: () => void,
    rsql?: string,
    reset = false
  ) {
    findAll({
      tableName: "syjRscBundleEntries",
      first: first,
      size: size,
      filter: rsql
    }).then(res => {
      dispatch({
        type: "loadData",
        payload: { data: res.data.data, first: first, reset: reset }
      });
      setRowCount(res.data.meta.totalRecords);
      if (reset) {
        listTradRef.current && listTradRef.current.resetScroll();
      }
    });
  },
  []);

  function loadMoreData(index: IndexRange) {
    return new Promise<void>(resolve => {
      loadData(
        index.startIndex,
        index.stopIndex - index.startIndex,
        resolve,
        rsqlFilter === undefined ? rsql.build() : rsqlFilter
      );
    });
  }

  useEffect(() => {
    loadData(0, 15, undefined, rsqlFilter === undefined ? rsql.build() : rsqlFilter, true);
  }, [loadData, rsqlFilter]);

  function clickItem(trad: Pojo) {
    setSelectedDataSource(trad);
    setTrad(trad);
  }

  useEffect(() => {
    if (props.column !== null) {
      const rsql = initRsqlCriteria();
      rsql.filters.and(new RSQLFilterExpression("id", Operators.Equal, props.column.columnId));
      findAll({ tableName: "syjInteractiveReportColumn", filter: rsql.build() }).then(res => {
        if (res.data.data) {
          setSyjIRColumn(res.data.data[0]);
        }
      });
    }
  }, [props.column]);

  function setTrad(pojo: Pojo | null) {
    if (pojo !== null && syjIRColumn !== null) {
      const newIRColumn = produce(syjIRColumn, draft => {
        draft["sjioSjrbResourceKey"] = pojo.sjrbResourceKey;
      });
      setSyjIRColumn(newIRColumn);
    }
  }

  function onValidate() {
    if (syjIRColumn !== null) {
      if (createNewTrad === true) {
        newIRColumnTrad("", newTrad, syjIRColumn.id).then(res => {
          if (res.data) {
            props.updateListWithColumn(res.data);
            props.onClose();
          }
        });
      } else {
        updateIRColumnTrad("syjInteractiveReportColumn", getUserLang(), syjIRColumn).then(res => {
          if (res.data) {
            props.updateListWithColumn(res.data);
            props.onClose();
          }
        });
      }
    }
  }
  return (
    <Modal
      onClose={props.onClose}
      onValidate={onValidate}
      height="50vh"
      title="Changement de traduction"
    >
      <>
        <Row>
          <Col>
            <InputTextAndLabel
              id="currentLabel"
              label="Traduction actuelle"
              value={props.column?.label}
              readOnly={true}
            />
          </Col>
          <Col>
            <CheckBoxAndLabel
              id="createNewTrad"
              label="Nouvelle traduction"
              onValueChange={() => setCreateNewTrad(!createNewTrad)}
              value={createNewTrad}
            />
            {createNewTrad === false && (
              <div style={{ height: "50vh" }}>
                <input
                  placeholder="Recherche"
                  type="text"
                  className="input is-small"
                  value={tradSearch}
                  onChange={e => setTradSearch(convertValue(e) ? convertValue(e) : "")}
                />
                <ListSelection
                  ref={listTradRef}
                  entities={dataSource}
                  rowCount={rowCount}
                  value={selectedDataSource}
                  clickItem={clickItem}
                  loadMoreData={loadMoreData}
                  field="sjrbResourceValue"
                  optionnalField="sjrbResourceKey"
                  fieldId="id"
                />
              </div>
            )}
            {createNewTrad === true && (
              <InputTextAndLabel
                id="newTrad"
                label="Traduction"
                value={newTrad}
                onChange={e => setNewTrad(convertValue(e))}
              />
            )}
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default ModalUpdateColumnLabel;
