import React, { FC, useMemo, MouseEvent, useCallback, useEffect, useState } from "react";
import LoaderContainer from "composants/LoaderContainer";
import { DataInteractionContext } from "hooks/useInteractions";
import MenuNavigationExpert from "composants/menu/MenuNavigationExpert";
import Modal from "composants/Modal/Modal";
import { Trans, useTranslation } from "react-i18next";
import { SuperUserLink } from "composants/userSettings/SuperUser";
import { useMachine } from "@xstate/react";
import formMachine, { isFormLoadingState } from "machine/FormMachine";
import Loader from "composants/Loader";
import { track } from "tracking";
import GroupComponent from "composants/group/GroupComponent";
import Datatable from "containers/datatable/Datatable";
import { ComponentGroupState } from "types/ComponentGroup";
import { useDispatch, useSelector } from "react-redux";
import { showContextMenu } from "actions/contextMenu";
import { getDimensionFromEvent, getOptionsByType, getReadonlyValue } from "utils/component.utils";
import { Fa } from "composants/Icon";
import classNames from "classnames";
import { SimpleComponentAndLabel } from "enum";
import { AutocompleteProps } from "composants/autocomplete/AutoComplete";
import { getCompos, convertValue } from "utils/entities.utils";
import { Pojo } from "types/Galaxy";
import { ReducerState } from "reducers";
import { getExpertGroups, getExpertHeader, findOne } from "api";

import "style/grid.css";
import {
  json,
  LoaderFunction,
  matchPath,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { selectGalaxyInformation } from "selectors";
import { ComponentState } from "types/Component";
import { ON_CLOSE_EXPERT_CALLBACK } from "constant/expert";
import { Button, Stack } from "@axin-org/comet";
import { t } from "i18next";
import { tw } from "twind";

interface ExpertProps {
  sjmoCode: string;
  height: string | number;
}

function selectGalaxyInfo(sjmoCode: string) {
  return (reducer: ReducerState) => {
    return selectGalaxyInformation(reducer, sjmoCode);
  };
}

type LoaderType = {
  header: ComponentState[];
  groups: ComponentGroupState[];
};

export function ExpertRedirect({ sjmoCode }: { sjmoCode: string }) {
  const params = useParams();
  const navigate = useNavigate();
  const menu = useSelector(
    useCallback((state: ReducerState) => state.expert.menu[sjmoCode] ?? [], [sjmoCode])
  );
  useEffect(() => {
    if (menu.length > 0 && !params.expertId) {
      navigate(menu[0].code, { replace: true });
    }
  }, [menu, navigate, params.expertId]);
  return (
    <React.Suspense fallback={null}>
      <Outlet />
    </React.Suspense>
  );
}

async function getGroups(sjmoCode: string, expertId?: string) {
  let groups: ComponentGroupState[] = [];
  if (expertId) {
    if (loaderCacheGroups.has(expertId)) {
      groups = loaderCacheGroups.get(expertId) ?? [];
    } else {
      groups = await getExpertGroups(sjmoCode as string, expertId as string).then(res => res.data);
      loaderCacheGroups.set(expertId, groups);
    }
  }
  return groups;
}

const loaderCacheHeader = new Map<string, LoaderType["header"]>();
const loaderCacheGroups = new Map<string, LoaderType["groups"]>();
export const loader: LoaderFunction = async ({ params, request }) => {
  const match = matchPath({ path: "/page/:code", end: false }, new URL(request.url).pathname);

  if (match) {
    const code = match.params.code as string;

    let header: ComponentState[];
    if (loaderCacheHeader.has(code)) {
      header = loaderCacheHeader.get(code) ?? [];
    } else {
      header = await getExpertHeader(match.params.code as string).then(
        res => (res.data as any) as ComponentState[]
      );
      loaderCacheHeader.set(match.params.code as string, header);
    }

    const groups = await getGroups(code, params.expertId);

    return json({ header, groups });
  }

  return json({ header: [], groups: [] });
};

const Expert: FC<ExpertProps> = props => {
  const navigate = useNavigate();
  const params = useParams<{ id: string; expertId?: string }>();
  const location = useLocation();
  const galaxieInfo = useSelector(selectGalaxyInfo(props.sjmoCode));
  const [searchParams] = useSearchParams();
  const tableName: string | undefined = searchParams.get("tableName") ?? galaxieInfo?.tableName;
  const [t] = useTranslation();

  const { header, groups } = useLoaderData() as LoaderType;
  const [activeTab, setActiveTab] = useState(0);

  const expertParentProps = useMemo(() => {
    return {
      ctrlKey: "expert",
      tableName: tableName
    };
  }, [tableName]);

  const menu = useSelector(
    useCallback((state: ReducerState) => state.expert.menu[props.sjmoCode] ?? [], [props.sjmoCode])
  );
  const reduxDispatch = useDispatch();

  useEffect(() => {
    setActiveTab(0);
  }, [params.expertId]);

  const currentGroup = groups;

  const cacheComponents = useMemo(() => {
    const headerCompos = header as ComponentState[];
    const groupCompos = getCompos(currentGroup);

    return headerCompos.concat(groupCompos);
  }, [currentGroup, header]);

  const [state, send] = useMachine(formMachine);

  const fetchEntity = useCallback(() => {
    // on met un IF parce que l'admin ne charge pas de donnée
    if (params.id && tableName) {
      const id = decodeURIComponent(params.id);
      findOne({ tableName: tableName, id, includeStyle: true }).then(res =>
        send({ type: "LOAD_ENTITY", sjmoCode: props.sjmoCode, entity: res.data })
      );
    }
  }, [props.sjmoCode, tableName, params.id, send]);

  useEffect(() => {
    fetchEntity();
  }, [fetchEntity]);

  function changeValue(field: string, value: any) {
    let currentCompo = cacheComponents.find(compo => compo.column === field);

    send({
      type: "CHANGE",
      field,
      sjmoCode: props.sjmoCode,
      compo: currentCompo?.typeCompo ?? "I",
      value,
      wvi: currentCompo?.wvi ?? false
    });
  }

  function onChange(e: React.SyntheticEvent<any>) {
    const field = e.currentTarget.name;
    const value = convertValue(e);
    changeValue(field, value);
  }

  function onBlur(e: React.SyntheticEvent<any>) {
    const field: string = e.currentTarget.name;

    let currentCompo = cacheComponents.find(compo => compo.column === field);

    send({ type: "BLUR", field, sjmoCode: props.sjmoCode, wvi: currentCompo?.wvi ?? false });
  }

  function onItemChange(selectedItem: Pojo | null, field: string) {
    changeValue(field, selectedItem ? selectedItem.id : null);
  }

  function onValueChange(field: string | undefined, value: string) {
    if (!field) return;
    changeValue(field, value);
  }

  const onContextMenu = (field: string) => (event: MouseEvent<any>) => {
    if (event.ctrlKey) {
      return;
    }
    event.preventDefault();
    if (state.context.entity) {
      const dimension = getDimensionFromEvent(event);
      reduxDispatch(
        showContextMenu(
          dimension.x,
          dimension.y,
          props.sjmoCode,
          tableName,
          field,
          state.context.entity.id,
          state.context.entity[field],
          null, // genericEntity uniquement pour les listes génériques
          "expert",
          true
        )
      );
    }
  };

  function onClose() {
    if (state.matches("idle")) {
      try {
        // on execute le callback
        window[ON_CLOSE_EXPERT_CALLBACK] && (window as any)[ON_CLOSE_EXPERT_CALLBACK]();
      } catch (e) {
        console.log(e);
      } finally {
        // on vide le callback pour éviter de polluer l'object global
        // avec des fonctions. Cela permet de faire passer le garbage collector
        // pour la fonction callback.
        (window as any)[ON_CLOSE_EXPERT_CALLBACK] = undefined;
      }
    }
    navigate(location.pathname.substring(0, location.pathname.indexOf("/expert")));
  }

  function createHeader() {
    return header.map(compo => {
      const SelectedComponent = SimpleComponentAndLabel[compo.typeCompo];
      const {
        typeCompo,
        contentSize,
        mandatory,
        joinTableName,
        joinListFields,
        joinDisplayedFields,
        additionalClause,
        sortClause,
        wvi,
        readOnly,
        disabled,
        compoVisible,
        isNumber,
        defaultValue,
        hasLov,
        ...restProps
      } = compo;

      const wviState = state.context.result[compo.column];

      const style = state.context.entity?._style ? state.context.entity._style[compo.column] : {};

      // on ajoute la méthode onItemChange spécifique de la GS

      const propsGS: Partial<AutocompleteProps> =
        typeCompo === "GS"
          ? {
              joinTableName,
              joinListFields,
              additionalClause,
              sortClause,
              onItemChange: onItemChange,
              controlProps: { expanded: true },
              sjmoCode: props.sjmoCode,
              parent: expertParentProps,
              styleInput: style,
              tableName: tableName,
              hasLov
            }
          : {};

      // on  ajoute la méthode onValueChange spécifique à l'éditeur
      const propsEditor =
        typeCompo === "ED"
          ? {
              onValueChange: onValueChange
            }
          : {};

      const propsToSelectedComponent = {
        style,
        ...restProps,
        ...propsGS,
        ...propsEditor
      };

      const combinedReadOnly =
        getReadonlyValue(readOnly, state.context.entity?.version ?? null) || false;
      const combinedDisable =
        getReadonlyValue(disabled, state.context.entity?.version ?? null) || false;

      const options = getOptionsByType(compo);

      return (
        <SelectedComponent
          key={compo.column}
          name={compo.column}
          onChange={onChange}
          {...propsToSelectedComponent}
          {...options}
          onBlur={onBlur}
          onContextMenu={onContextMenu(compo.column)}
          id={compo.column}
          value={state.context.entity?.[compo.column] ?? null}
          required={mandatory}
          wviState={wviState ? wviState.code : null}
          wviMessage={wviState ? wviState.message : null}
          readOnly={combinedReadOnly}
          disabled={combinedDisable}
          onValueChange={typeCompo === "ED" || typeCompo === "CH" ? onValueChange : undefined}
        />
      );
    });
  }

  function createExpertContent() {
    // on cherche le group actif
    const current = currentGroup.find((_, i) => i === activeTab);

    if (current == null) return null;

    if (current.compos.length > 0) {
      return (
        <GroupComponent
          key={activeTab}
          group={current}
          onChange={onChange}
          onBlur={onBlur}
          onItemChange={onItemChange}
          onValueChange={onValueChange}
          contextMenu={onContextMenu}
          entity={state.context.entity ?? {}}
          groupSize={current.groupSize}
          wviState={state.context.result}
          sjmoCode={props.sjmoCode}
          parent={expertParentProps}
          tableName={tableName}
        />
      );
    } else if (current.datatableTableName != null) {
      return (
        <Datatable
          key={current.datatableCtrlKey}
          sjmoCode={props.sjmoCode}
          tableName={current.datatableTableName}
          ctrlkey={current.datatableCtrlKey}
        />
      );
    }

    return null;
  }

  const isLoading = isFormLoadingState(state);
  const isStarting = state.matches({ initial: "slow" }) || state.matches({ initial: "stuck" });
  const isStuck = state.matches({ validating: "stuck" }) || state.matches({ saving: "stuck" });

  const expertContent = isStarting ? <Loader /> : createExpertContent();

  return (
    <Modal
      title={
        <>
          <Trans i18nKey="commun_expert">Expert</Trans>
          <button className="button is-text is-small" onClick={fetchEntity}>
            <span>{t("commun_rafraichir")}</span>
            <span className="icon is-small">
              <Fa icon={["fas", "sync-alt"]} />
            </span>
          </button>
          <SuperUserLink url={`/admin/expert/${props.sjmoCode}`} className="ml-6" />
        </>
      }
      minWidth="90vw"
      minHeight="70vh"
      disableFooterButton={isLoading}
      onValidate={() => {
        send({ type: "SAVE", sjmoCode: props.sjmoCode });
        track("expert::save::click");
      }}
      onClose={() => {
        onClose();
        track("expert::cancel");
      }}
    >
      {isStuck && (
        <div className="flex justify-center">
          <div className="tag is-large is-warning is-light">
            <span style={{ marginRight: ".5em" }}>
              <Fa icon={["fas", "exclamation-triangle"]} />
            </span>
            <span>{t("commun_lenteur_merci_de_patienter")}</span>
          </div>
        </div>
      )}
      <LoaderContainer className="columns" loading={isLoading} style={{ height: props.height }}>
        <DataInteractionContext.Provider value={state.context.entity}>
          <div className="column is-one-quarter">
            <MenuNavigationExpert
              basePath={`/page/${props.sjmoCode}/${encodeURIComponent(params.id as string)}/expert`}
              menu={menu}
            />
          </div>
          <div className="column is-three-quarters">
            <div className="gridWrapper2Cols">{createHeader()}</div>
            <div className="tabs">
              <ul>
                {currentGroup.map((group, i) => {
                  return (
                    <li
                      key={group.groupLabel}
                      className={classNames({ "is-active": i === activeTab })}
                      onClick={() => {
                        track("expert::tabs::changed");
                      }}
                    >
                      <a onClick={() => setActiveTab(i)}>{group.groupLabel}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {expertContent}
          </div>
        </DataInteractionContext.Provider>
      </LoaderContainer>
    </Modal>
  );
};

export const ExpertNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function closeModal() {
    navigate(-1);
  }
  return (
    <Modal
      title={<Trans i18nKey="commun_expert">Expert</Trans>}
      onClose={closeModal}
      show
      hideFooter
    >
      <div className={tw("flex justify-center")}>
        <Stack className={tw("text-center")} gap={3}>
          <p className={tw("font-semibold text-xl")}>{t("commun_expert_not_found")}</p>
          <Button onClick={closeModal} intent="primary" autoFocus>
            {t("commun_fermer")}
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};

export default Expert;
