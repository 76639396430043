import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "store";
import { URL_DATA, URL_ADN, URL_UTILS, URL_TEMPLATE_SERVER } from "customGlobal";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import Cookie from "js-cookie";

import { FilterBar } from "types/Search";
import { uuidv4 } from "utils/uuid.utils";
import toaster from "composants/toaster/toaster";
import { format } from "date-fns";
import { NotificationGroup } from "types/Notification";

import auth, { updateToken } from "auth";
import { COOKIE_LANG } from "utils/network.utils";

/**
 * Permet de créer un URLSearchParams avec les données génériques de notres service
 * - modeDebug
 * - superUser
 * - sjmoCode
 * - colOrigine
 * @param sjmoCode code module
 * @param column colone
 */
export function getUIContext({
  sjmoCode,
  contextKey,
  column,
  labelContext,
  labelDetailsContext,
  focusId
}: {
  sjmoCode?: string;
  contextKey?: string;
  column?: string;
  labelContext?: string;
  labelDetailsContext?: string;
  focusId?: string | null;
} = {}): URLSearchParams {
  const url = new URLSearchParams();
  url.append("modeDebug", sessionStorage.getItem("modeDebug") || "false");
  url.append("superUser", sessionStorage.getItem("superUser") || "false");
  url.append("adminLevel", sessionStorage.getItem("adminLevel") || "0");

  const societeEnCours = sessionStorage.getItem("societeEnCours");
  if (societeEnCours) url.append("societeEnCours", societeEnCours);

  url.append("colOrigine", column || "");

  labelContext && url.append("labelContext", labelContext);
  labelDetailsContext && url.append("labelDetailsContext", labelDetailsContext);
  sjmoCode && url.append("sjmoCode", sjmoCode);
  contextKey && url.append("contextKey", contextKey);
  focusId && url.append("focusId", focusId);

  return url;
}

export function getFilterBar(
  { filterBarId, startDate, endDate, filterBarDefaultDtFilter }: FilterBar = {
    filterBarId: null,
    startDate: null,
    endDate: null
  }
) {
  const url = new URLSearchParams();

  if (filterBarId) {
    url.append("filterBarId", filterBarId.toString());
  }

  if (startDate) {
    url.append("filterBarStart", startDate);
  }

  if (endDate) {
    url.append("filterBarEnd", endDate);
  }

  if (filterBarDefaultDtFilter) {
    url.append("filterBarDefaultDtFilter", filterBarDefaultDtFilter);
  }

  return url;
}

/**
 * Gestion des matrix de spring. Prend un object et retourne un string
 * @param matrix object à mapper
 */
export function createContextPreRecord(matrix: Record<string, object> = {}): URLSearchParams {
  const urlParams = new URLSearchParams();
  const keys = Object.keys(matrix);
  keys.forEach(key => urlParams.append(key, matrix[key] + ""));

  return urlParams;
}

let axiosInstance = axios.create({
  baseURL: URL_DATA()
});

/**
 * Permet de gérer les messages survenant côté serveur.
 * On ne dispatch le message que si le paramètre visual est "global"
 * @param error message d'erreur lancé par l'api
 */
function handleError(error: AxiosResponse<Message>) {
  if (error && error.data.target === "GLOBAL") {
    store && store.dispatch(addMessage(error.data));
  }
  if (error && error.data.target === "CONSOLE") {
    console.warn(error.data.message);
  }
}

axiosInstance.interceptors.request.use(
  async config => {
    await updateToken();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${auth.token}`;
      config.headers["Accept-Language"] = Cookie.get(COOKIE_LANG) ?? "";
    }
    return config;
  },
  (error: AxiosError<any>) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  res => res,
  (error: AxiosError<any>) => {
    if (error.response && (error.response as AxiosResponse).status === 401) {
      // TODO : il faut gérer le cas ici ?
      return Promise.reject(error);
    }

    if (
      error.response &&
      typeof error.response.data === "string" &&
      error.response.data !== "" &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      toaster.notify({
        id: uuidv4(),
        group: NotificationGroup.DEFAULT,
        title: error.response.data,
        createdAt: format(new Date()),
        intent: "DANGER",
        priority: "CRITICAL"
      });
    } else {
      // TODO match error message before the catch.
      // handle message pourrait aussi être store.dispatch({type:HANDLE_ERROR, message})
      handleError(error.response as AxiosResponse<Message>);
    }

    // retour par défaut
    return Promise.reject(error);
  }
);

export const api = axiosInstance;

let axiosInstanceAdn = axios.create({
  baseURL: URL_ADN()
});

axiosInstanceAdn.interceptors.request.use(
  async config => {
    await updateToken();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${auth.token}`;
      config.headers["Accept-Language"] = Cookie.get(COOKIE_LANG) ?? "";
    }
    return config;
  },
  (error: AxiosError<any>) => {
    return Promise.reject(error);
  }
);

axiosInstanceAdn.interceptors.response.use(
  res => res,
  (error: AxiosError<any>) => {
    if (error.response && (error.response as AxiosResponse).status === 401) {
      // TODO : il faut gérer le cas ici ?
      return Promise.reject(error);
    }
    // TODO match error message before the catch.
    // handle message pourrait aussi être store.dispatch({type:HANDLE_ERROR, message})
    handleError(error.response as AxiosResponse<Message>);

    // retour par défaut
    return Promise.reject(error);
  }
);

export const apiAdn = axiosInstanceAdn;

let axiosInstanceUtils = axios.create({
  baseURL: URL_UTILS()
});

axiosInstanceUtils.interceptors.request.use(
  async config => {
    await updateToken();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${auth.token}`;
      config.headers["Accept-Language"] = Cookie.get(COOKIE_LANG) ?? "";
    }
    return config;
  },
  (error: AxiosError<any>) => {
    return Promise.reject(error);
  }
);

axiosInstanceUtils.interceptors.response.use(
  res => res,
  (error: AxiosError<any>) => {
    if (error.response && (error.response as AxiosResponse).status === 401) {
      // TODO : il faut gérer le cas ici ?
      return Promise.reject(error);
    }
    // TODO match error message before the catch.
    // handle message pourrait aussi être store.dispatch({type:HANDLE_ERROR, message})
    handleError(error.response as AxiosResponse<Message>);

    // retour par défaut
    return Promise.reject(error);
  }
);

export const apiUtils = axiosInstanceUtils;

let axiosInstanceTemplateServer = axios.create({
  baseURL: URL_TEMPLATE_SERVER()
});

axiosInstanceTemplateServer.interceptors.request.use(
  async config => {
    await updateToken();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${auth.token}`;
      config.headers["Accept-Language"] = Cookie.get(COOKIE_LANG) ?? "";
      config.headers["x-axin-user"] = (auth.tokenParsed as any).user_name;
    }
    return config;
  },
  (error: AxiosError<any>) => {
    return Promise.reject(error);
  }
);

axiosInstanceTemplateServer.interceptors.response.use(
  res => res,
  (error: AxiosError<any>) => {
    if (error.response && (error.response as AxiosResponse).status === 401) {
      // TODO : il faut gérer le cas ici ?
      return Promise.reject(error);
    }
    // TODO match error message before the catch.
    // handle message pourrait aussi être store.dispatch({type:HANDLE_ERROR, message})
    handleError(error.response as AxiosResponse<Message>);

    // retour par défaut
    return Promise.reject(error);
  }
);

export const apiTemplateServer = axiosInstanceTemplateServer;
