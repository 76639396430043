import { SyntheticEvent, FC, useMemo, useState, useEffect, useCallback } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import GroupComponent from "../group/GroupComponent";
import { Button } from "../button";
import { ComponentState, Item } from "types/Component";
import { convertValue } from "utils/entities.utils";
import { fetchExtensionData, saveExtensions, createNewExtension, findAll, deleteMany } from "api";
import { AxiosError, AxiosResponse } from "axios";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import TabHeader from "./TabHeader";
import { Pojo } from "types/Galaxy";
import { Row, Col } from "composants/Layout";
import SysDomaineAndLabel from "composants/select/SysDomaineAndLabel";
import { Fa } from "composants/Icon";
import { Trans } from "react-i18next";
import { tw } from "twind";
import { initRsqlCriteria } from "utils/query.utils";
import { RSQLFilterExpression, Operators } from "rsql-criteria-typescript";
import InteractiveReport from "containers/datatable/InteractiveReport";
import { ComponentGroupState } from "types/ComponentGroup";

export type ExtensionType = "A" | "N" | "D" | "C" | "S";
export interface ExtensionValue {
  id: string;
  columnName: string;
  labelFormatter: any;
  dataType: string;
  value: any;
  unite: string;
  style: string;
}

export interface ExtensionsData {
  values: ExtensionValue[];
  composants: ComponentGroupState[];
}

function onBlur(e: SyntheticEvent<any>) {
  // pas de WVI sur les extensions
}

function mapExtensionResponseToState(response: AxiosResponse<ExtensionsData[], any>) {
  const metaData = {};
  response.data[0].values.forEach((v: ExtensionValue) => {
    metaData[v.columnName] = v;
  });

  // Mise à jour des label dans les définitions
  const updatedLabel = response.data[0].composants.map((g: ComponentGroupState) => {
    const compos = g.compos.map(c => {
      if (c.column) {
        c.label = c.label ? `${c.label} (${c.column})` : "";
      }
      return c;
    });
    g.compos = compos;
    return g;
  });

  // Mise à jours des labels sur les values
  const flatCompoList: ComponentState[] = [];
  response.data[0].composants.forEach(group => {
    group.compos.forEach(c => {
      flatCompoList.push(c);
    });
  });
  const updateLabelValue = response.data[0].values.map((v: ExtensionValue) => {
    const matchingcompo = flatCompoList.find(label => v.id === label.id);
    if (matchingcompo) {
      v.labelFormatter = matchingcompo.label;
    }
    return v;
  });
  return { def: updatedLabel, val: updateLabelValue };
}

const DeleteBody: FC<{
  sjmoCode: string;
  extensions: ExtensionValue[];
  isDeleting: boolean;
  rowSelectedToDelete: string;
  updateDeleteValue: (isDeleting: string) => void;
  refresh: () => void;
  addMessage(message: Message): void;
}> = props => {
  const sysDomaineChoices: Item[] = useMemo(() => {
    return props.extensions.map(ext => {
      return { label: ext.labelFormatter, value: ext.id };
    });
  }, [props.extensions]);

  return (
    <Row>
      <Col span={9}>
        <SysDomaineAndLabel
          id="extensionId"
          value={props.rowSelectedToDelete}
          onChange={e => {
            props.updateDeleteValue(convertValue(e));
          }}
          sysDomaineChoices={sysDomaineChoices}
          label={t("commun_extensions")}
        />
      </Col>
    </Row>
  );
};

const TabExtension: FC<TabSatelliteProps> = ({
  tableName,
  height,
  contextId,
  sjmoCode,
  count,
  countAction,
  addMessage,
  shouldRefreshGalaxie
}) => {
  const [definition, setDefinition] = useState<ComponentGroupState[]>([]);
  const [extensions, setExtensions] = useState<ExtensionValue[]>([]);
  const [isCreatingFromExisting, setIsCreatingFromExisting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [rowSelectedToDelete, setRowSelectedToDelete] = useState("");
  const [irSelectionExtension, setIrSelectionExtension] = useState<Pojo | null>(null);
  const [rowSelectedInIR, setRowSelectedInIR] = useState<Pojo[] | null>(null);

  const refresh = useCallback(() => {
    fetchExtensionData(tableName, contextId as string)
      .then(response => {
        const { def, val } = mapExtensionResponseToState(response);
        setDefinition(def);
        setExtensions(val);
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });

    countAction(tableName, contextId);
    setIsCreatingFromExisting(false);
    setIsDeleting(false);
    setRowSelectedToDelete("");
  }, [addMessage, contextId, countAction, tableName]);

  useEffect(() => {
    if (contextId) {
      refresh();
    }

    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("sjinCode", Operators.Equal, "SELECTION_EXTENSION"));
    findAll({
      tableName: "syjInteractiveReport",
      filter: rsql.build(),
      includeJoinParent: true
    }).then(res => {
      if (res.data && res.data.data.length > 0) {
        setIrSelectionExtension(res.data.data[0]);
      }
    });
    // lancement à l'ouverture
  }, []);

  const save = useCallback(async () => {
    let metaData = {};
    extensions.forEach((v: ExtensionValue) => {
      metaData[v.columnName] = v;
    });

    if (isDeleting && rowSelectedToDelete !== "") {
      // Suppression de l'extension dans la liste des extensions
      const keyToDelete = Object.keys(metaData).find(
        key => metaData[key].id === rowSelectedToDelete
      );
      if (keyToDelete) {
        delete metaData[keyToDelete];
      }
      // Suppression de l'extension en base
      await deleteMany("dataExtension", [rowSelectedToDelete], sjmoCode).then(response => {
        setRowSelectedToDelete("");
      });
    }

    if (isCreatingFromExisting && rowSelectedInIR) {
      for (let itemSelectedExtension of rowSelectedInIR) {
        await createNewExtension({
          sjmoCode: sjmoCode,
          tableName: tableName,
          contextId: contextId as string,
          fromExisting: true,
          data: {
            extensionId: itemSelectedExtension.extensionId
          }
        }).then(response => {
          setIsCreatingFromExisting(false);
          setRowSelectedInIR(null);
        });
      }
    }

    saveExtensions(sjmoCode, tableName, contextId as string, metaData)
      .then(response => {
        const { def, val } = mapExtensionResponseToState(response);
        setDefinition(def);
        setExtensions(val);
        countAction(tableName, contextId as string);
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  }, [
    addMessage,
    contextId,
    countAction,
    extensions,
    isCreatingFromExisting,
    isDeleting,
    rowSelectedInIR,
    rowSelectedToDelete,
    sjmoCode,
    tableName
  ]);

  // Refresh uniquement quand on change de contextId (cas ouverture depuis une datatable)
  useEffect(() => {
    if (contextId) {
      refresh();
    }
  }, [contextId]);

  const onChange = useCallback(
    (e: SyntheticEvent<any>) => {
      const field: string = e.currentTarget.name;
      const value = convertValue(e);

      let currentExtensions = [...extensions];
      currentExtensions = currentExtensions.map(ext => {
        let newValue = value;
        if (ext.dataType === "C") {
          newValue = value === true ? "O" : "N";
        }

        if (ext.columnName === field) {
          return { ...ext, value: newValue };
        } else {
          return ext;
        }
      });
      setExtensions(currentExtensions);
    },
    [extensions]
  );

  const onValueChange = useCallback(
    (field: string | undefined, value: any) => {
      let currentExtensions = [...extensions];
      currentExtensions = currentExtensions.map(ext => {
        let newValue = value;
        if (ext.dataType === "C") {
          newValue = value === true ? "O" : "N";
        }

        if (ext.columnName === field) {
          return { ...ext, value: newValue };
        } else {
          return ext;
        }
      });
      setExtensions(currentExtensions);
    },
    [extensions]
  );

  const onItemChange = useCallback(
    (pojo: Pojo, name: string) => {
      let currentExtensions = [...extensions];
      currentExtensions = currentExtensions.map(ext => {
        let newValue = pojo?.id;

        if (ext.columnName === name) {
          return { ...ext, value: newValue };
        } else {
          return ext;
        }
      });
      setExtensions(currentExtensions);
    },
    [extensions]
  );

  const extensionValues = useMemo(() => {
    const values = {};
    const _style = {};
    extensions.forEach((v: ExtensionValue) => {
      values[v.columnName] = v.value;
      if (v.style) {
        _style[v.columnName] = v.style;
      }
    });
    values["_style"] = _style;
    return values;
  }, [extensions]);

  return (
    <>
      <TabHeader
        i18nKey="commun_extensions_liees"
        tableName={tableName}
        contextId={contextId}
        count={count}
        sjmoCode={sjmoCode}
      >
        <Button
          className={tw(
            "bg-green-500 px-5 text-white rounded-3xl mr-6 border-none hover:text-white focus:text-white"
          )}
          onClick={() => save()}
          disabled={contextId === undefined}
          title={t("commun_valider")}
        >
          <span>
            <Fa icon="save" className="mr-7" />
          </span>
          <span>
            <Trans i18nKey="commun_valider" />
          </span>
        </Button>
        <Button
          className={tw(
            "bg-blue-600 text-white rounded-3xl px-5 hover:text-white focus:text-white mr-6"
          )}
          onClick={() => {
            setIsCreatingFromExisting(!isCreatingFromExisting);
            setIsDeleting(false);
          }}
          disabled={contextId === undefined}
        >
          <span>
            <Fa icon="plus" className="mr-7" />
          </span>
          <span>
            {isCreatingFromExisting ? (
              <Trans i18nKey="commun_cacher" />
            ) : (
              <Trans i18nKey="commun_ajouter" />
            )}
          </span>
        </Button>
        <Button
          className={tw(
            "bg-blue-600 text-white rounded-3xl px-5 hover:text-white focus:text-white mr-6"
          )}
          onClick={() => {
            setIsDeleting(!isDeleting);
            setIsCreatingFromExisting(false);
          }}
          disabled={contextId === undefined}
        >
          <span>
            <Fa icon="trash" className="mr-7" />
          </span>
          <span>
            {isDeleting ? <Trans i18nKey="commun_cacher" /> : <Trans i18nKey="commun_supprimer" />}
          </span>
        </Button>
      </TabHeader>
      {isCreatingFromExisting && irSelectionExtension?.id && (
        <div className={tw("h-96")}>
          <InteractiveReport
            sjmoCode={sjmoCode}
            definitionId={irSelectionExtension?.id ?? ""}
            isAdmin={false}
            isProcessusActive={false}
            onAfterSaveDatatable={() => refresh()}
            onChangeSelectedEntities={(e: Pojo[]) => {
              setRowSelectedInIR(e);
            }}
          />
        </div>
      )}
      {isDeleting && (
        <DeleteBody
          sjmoCode={sjmoCode}
          extensions={extensions}
          refresh={refresh}
          updateDeleteValue={(e: string) => {
            setRowSelectedToDelete(e);
          }}
          isDeleting={isDeleting}
          addMessage={addMessage}
          rowSelectedToDelete={rowSelectedToDelete}
        />
      )}

      {definition &&
        definition.map(g => (
          <>
            {g.compos && (
              <GroupComponent
                title={g.groupLabel}
                group={g}
                groupSize={12}
                entity={extensionValues}
                wviState={{}}
                onChange={onChange}
                onValueChange={onValueChange}
                onItemChange={onItemChange}
                onBlur={onBlur}
                contextMenu={() => {
                  /* rien */
                }}
                sjmoCode={sjmoCode}
              />
            )}
          </>
        ))}
    </>
  );
};

export default TabExtension;
